import React from "react";
import "./Footer.css";
import facebook from "../assets/facebooklarge-min.png";
import twitter from "../assets/twitterlarge-min.png";
import youtube from "../assets/youtubelarge-min.png";
import telegram from "../assets/telegram.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="footer">
        <div>ShareDisk © 2022-2023 - All rights reserved. </div>
        <div className="nav-links">
          <Link to="/company">About Us</Link>
          <Link to="/contact-us">Contact Us</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-and-conditions">Terms & Conditions</Link>
          <Link to="/refund-policy">Refund Policy</Link>
          <a href="https://spdjmedia.com/product/sharedisk/">Pricing</a>
        </div>
        <div className="socials">
          <a target="_blank" href="https://www.facebook.com/sharedisk/">
          <img src={facebook} alt="" />
          </a>
          <a target="_blank" href="https://twitter.com/sharedisk">
          <img src={twitter} alt="" />
          </a>
          <a target="_blank" href="https://www.youtube.com/@sharedisk">
          <img src={youtube} alt="" />
          </a>
          <a target="_blank" href="https://t.me/sharediskofficial">
            <img src={telegram} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}
