import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import "./ContactUs.css";
export default function PrivacyPolicy() {
  return (
    <div>
      <NavBar />
      <div className="tile-container">
        <div className="blur-tile privacy">
          <div className="contact-ui">
            <div className="large-title help">Privacy Policy</div>
            <div className="sub-text">
            Privacy Policy

This Privacy Policy shall be read in conjunction and together with the Terms of Use / User Agreement forms an integral part of the User Agreement between You ( “User(s) or you” ) and SD Player (“We/Our/Us/SD Player”). Capitalized terms not defined herein shall have the meaning attributed to them under the Terms of Use / User Agreement

1. SD Player Terms of Privacy Policy

Privacy Policy describes how and when SD Player collects, processes, uses, and shares your information when you use SD Player, including our mobile applications and websites (the "Services"). SD Player receives your information through our mobile applications, websites, email notifications, and other interactions with our Services. By accessing and/or using the Services, you consent and authorize us to the collection, transfer, processing, storage, disclosure, and other uses of your information as described in this Privacy Policy. If you do not agree with any or all of the following terms of the Privacy Policy, please Do Not Use and/or access the Services.

2. Personal Data Collection and Use

We use Personal Information to facilitate and improve our Services and communicate with you following existing regulations, while data integrity, accuracy, relevancy, and legally justified processing are ensured. The data we collect depends on the context of your interactions with us, the choices you make, including your privacy settings, and the products and features listed below (collectively “Personal Information” or “Personal Data”):

2.1 General Information

The general data we collect can include SDK/API/JS code version, browser, Internet service provider, IP address, platform, timestamp, application identifier, application version, application distribution channel, independent device identifier, Android ad master identifier, network card (MAC) address, and international mobile device identification code (IMEI) the equipment model, the terminal manufacturer, the terminal device operating system version, the session start/stop time, the location of the language, the time zone and the network state (WiFi and so on).

2.2 Finding Friends and Connecting to Other Services

Services include several features to help connect you to the accounts of people you already know. For example, you may upload information from your address book or connect your SD Player account to your account on another service such as Twitter. SD Player will match the information you provide with the contact information of other SD Player users in order to provide and improve the Services, for example by making recommendations and routing SD Player messages. You may remove address book data from SD Player at any time through the settings in your SD Player app. When you connect your SD Player account to other services, you are able to post your content to those services as well. You may disconnect your SD Player account from your account on the other services at any time.
Your SD Player account will immediately revoke our authorization to cross-post on your behalf or otherwise access that account and we will delete information obtained from that connection (other than information that was publicly posted on SD Player, such as profile information), which may take some time.

2.3 Content, Following, and other Public Information
SD Player is an Over the Top (OTT) digital on-demand Service that offers a wide range of content for entertainment purposes including without limitations, audio, visual, audiovisual, and gaming content, so most of the information you provide us is information that you choose to be made public. This includes not only the content that you post and data provided with such content, such as when it was posted, but also the accounts you follow, the content that you like or comment on, and other public interactions on the Services. Your public information is broadly and instantly disseminated. For instance, your profile information and public Content are immediately delivered to a wide range of users and other services that access SD Player. When you share information or content via the Services, you should think carefully about what you are making public.

2.4 Location Information

In addition to profile information, you may also tell us your exact location if you choose to enable your computer or mobile device to send us location information. We may use and store information about your location to manage content copyright and exploitation restrictions applicable to your location and to also provide features of our Services, such as tagging a video with a location, and to improve and customize the Services, for example, with content that has been tagged near your location.

2.5 Links

We may keep track of how you interact with links across our Services, including our email notifications, third-party services, and client applications, by redirecting clicks or through other means. We do this to help improve our Services, for example, to provide relevant advertising, and to be able to share aggregate click statistics such as how many times a particular link was clicked on.

2.6 Cookies
We may use "cookie" technology to collect additional website usage data and to improve our Services, but we do not require cookies for many parts of our Services such as viewing public videos. A cookie is a small data file that is transferred to your computer's hard disk. SD Player may use both session cookies and persistent cookies to better understand how you interact with our Services, to monitor aggregate usage by our users and web traffic routing on our Services, and to customize and improve our Services. Most Internet browsers automatically accept cookies. If you do not want cookies from SD Player or other websites, you can instruct your browser, by changing its settings, to stop accepting cookies or to prompt you before accepting a cookie from the websites you visit. However, some Services may not function properly if you disable cookies.

2.7 Log Data
The servers automatically record information ("Log Data") created by your use of the Services. Log Data may include information such as your IP address, browser type, operating system, the referring web page, pages visited, location, your mobile carrier, device and application IDs, search terms, and cookie information. We receive Log Data when you interact with our Services, for example, when you visit our websites, sign into our Services, view videos hosted by SD Player, or interact with our email notifications. SD Player uses Log Data to provide our Services and to measure, customize, and improve them. If not already done earlier, we will either delete Log Data or remove any common account identifiers, such as your username, full IP address, or email address, after 18 months or such other period as required by us.

2.8 Other Non-Personal Identification Information
We do not collect the "Personal Information" which can identify you or another person. We may collect non-personal identification information about Users whenever they interact with our apps. Non-personal identification information may include the type of your phone, apps, and other technical information about Users means of connection to our apps, such as the versions of the phone models and other similar information.

2.9 Third-Party Services
SD Player uses a variety of third-party services to help provide our Services, including hosting our various blogs and wikis and to help us understand the use of our Services. These third-party service providers may use cookies and other technologies to collect information about your use of our Services and other websites and services, including your IP address, device ID, pages viewed, and links clicked. 

2.10 Interest-Based Ads
In order to provide a personalized advertisement experience to you, third parties, including Facebook, may collect or receive information from our app and other apps and use that information to improve services and targeted ads.
On your Android, you can change your device settings to control whether you see online interest-based ads from Facebook and other companies.
On your Android (versions 2.2 and up) and Google Play Services (versions 4.0 and up):
Go to Google Settings
Top Ads
Tap Opt out of interest-based ads and tap OK
Based on your choice on the Admob ad personalization consent page, Admob & its partners will deliver personalized ads or non-personalized ads to you while using our Services. But, if you ever change your mind in the future, you can easily change your choice about interest-based ads anytime by:
Tap the Help menu in our app
Tap Ad Preferences and make your choice
Revisit our Services

3. Sensitive Personal Data and Information
SD Player will never ask you and you must never provide sensitive SD Player will never ask you and you must never provide Sensitive Personal Data Or Information to SD Player or to any person/entity representing SD Player. Any disclosure of Sensitive Personal Data Or Information shall be at your sole risk and without any liability to SD Player (including its directors, key managerial personnel, officers, and employees). You understand, acknowledge, and agree that SD Player or any other person acting on behalf of SD Player shall not in any manner be responsible for the authenticity of the Personal Information or Sensitive Personal Data or Information provided by you to SD Player.
You must note that any information that is freely available or accessible in the public domain shall not be regarded as Personal Information or Sensitive Personal Data or Information for the purposes of this Privacy Policy and SD Player shall not be obliged to take any measures to protect the same since the same is freely available in the public domain. Please further note that identity theft and the practice currently known as "phishing" are of great concern to SD Player. We do not and will not, at any time, request your credit card information/debit card information/financial pin numbers, and passwords.

4. Information Sharing and Disclosure
To improve our products and provide you with better service, We also share Personal Data with vendors or agents working on our behalf for the purposes described in this Privacy Policy. For example, companies we've hired to provide data analytical services may need to collect and access Personal Data to provide those functions. In such cases, these companies must abide by our data privacy and security requirements.

4.1 Your Consent
SD Player may share or disclose your information at your direction, such as when you choose to publicly post a video or update your profile information.

4.2 Service Providers

SD Player engages service providers to perform functions and provide services to us in India and abroad. We may share your Personal Information with such service providers subject to confidentiality obligations consistent with this Privacy Policy, and on the condition that the third parties use your Personal Data only on our behalf and pursuant to our instructions.

4.3 Law and Harm
SD Player may preserve or disclose your information if we believe that it is reasonably necessary to comply with a law, regulation, or legal request; to protect the safety of any person; to address fraud, security, or technical issues; or to protect SD Player’s rights or property. However, nothing in this Privacy Policy is intended to limit any legal defenses or objections that you may have to a third party's, including a government's, request to disclose your information.

4.4 Business Transfers
In the event that SD Player is involved in a bankruptcy, merger, acquisition, reorganization or sale of assets, your information may be sold or transferred as part of that transaction. The promises in this Privacy Policy will apply to your information as transferred to the new entity. By providing your information to us, you consent to any transfer and processing in accordance with this Privacy Policy.

4.5 International Transfers
If SD Player needs to host/ store its data which includes the Users data as available with SD Player to severs falling out of Users jurisdiction, SD Player will take all reasonable efforts to ensure that transfer and storage of such data to servers located outside the Users jurisdiction are bound by a duty of confidentiality.

4.6 Non-Private or Non-Personal Information
SD Player may share or disclose your non-private, aggregated, or otherwise non-personal information, such as your public user profile information, public posts, the people you follow or that follow you, or the number of users who clicked on a particular link (even if only one did).

5. Age of Consent and Child Policy
Our Services are primarily meant for the Users who have attained the age of 18 years or above and you have given us your consent to allow any of your minor dependents (less than 13 years) to use and access our Services. If you access our Services, then you shall be bound by our Age Suitability Terms.

6. Our Policy Towards Children

Our Services are not directed to minors who are under the age of 13. If you become aware that your child has provided us with Personal Information without your consent, please email spdjmedia@gmail.com. We do not knowingly collect personal information from children under the age of 13. If we become aware that a child under the age of 13 has provided us with personal information, we take steps to remove such information and terminate the child's account.

7. Legal Basis for Personal Data Processing

SD Player processes Personal Data for the purposes set out in this Privacy Policy, as described above. Our legal basis to process Personal Data includes processing that is: necessary for the performance of the contract with you (for example, to provide you with the services you request and to identify and authenticate you so you may use our Service); necessary to comply with legal requirements (for example, to comply with applicable accounting rules and to make mandatory disclosures to law enforcement); necessary for our legitimate interests (for example, to manage our relationship with you, to ensure the security of our services, to communicate with you about our products and services); and based on consent by our customers (for example, to place certain cookies and to share your information with third parties for advertising purposes).In some instances, you may be required to provide us with Personal Data for processing as described above, in order for us to be able to provide you all of our services, and for you to use all the features of our Services.

8. Third-Party Websites / Apps

Our Service may contain links to other websites/services owned and operated by third parties which are not under the control of SD Player ("Third-Party Websites/Apps"). Any Third Party Websites/App you visit by a link from our Service are solely the responsibility of the owners of such Third Party Websites/Apps. The content of, including materials and information contained on, any Third Party Websites/Apps to which you link from our Service is solely the responsibility of the owner and provider of such Third Party Websites/Apps. Any transactions that you enter into with a third party listed in this Service or linked from our Service are solely between you and the owner/provider of such Third Party Websites/Apps. SD Player is and shall not be responsible for any such Third Party Websites/Apps, any products or services sold or provided by such Third Party Websites/Apps, nor the organizations publishing those Third Party Websites/Apps, and hereby disclaim any responsibility and liability for the same. The inclusion of any links does not constitute or imply an endorsement or recommendation by us of the Third Party Websites/Apps and the services and products offered therein, of the quality of any product or service, advice, information, or other materials displayed, purchased, or obtained by you as a result of an advertisement or any other information or offer in or in connection with the Third Party Websites/Apps. SD Player shall not be liable, accountable, or responsible for your Personal Information and/or Sensitive Personal Data or Information being used or misused by such Third Party Websites/Apps.
access and usage of any Third Party Websites/Apps are entirely at your own risk. SD Player shall not be a party to any transaction between you and a Third Party Websites/Apps. Your use of a Third Party Websites/Apps is subject to the terms and conditions and privacy policy of such Third Party Websites/Apps in addition to our Terms of Use/User Agreement and Privacy Policy.

9. Limited Liability
Security of your Personal Information is of paramount importance to us and we want the same to be as safe as possible while using and accessing our Services. To this extent, we have implemented reasonable technical safeguards to protect your Personal Information. Whilst we will take all the reasonable steps to protect your Personal Information against potential risks and exposures, there is no absolute security in the online/ internet sphere as known to the public. Therefore, you must not disclose any information on our Services that is Sensitive to Personal Data or Information. You understand that the transmission of information over the internet is not completely secure and there are risks associated with it. Although we strive to protect your personal information, we cannot guarantee the security of the same while it is being transmitted to our Services and any transmission is at your own risk.

10. Changes to this Policy
We may revise this Privacy Policy from time to time without any further notification (except for material changes). The most current version of the policy will govern our use of your Personal Information and will always be updated and available at https://www.SDplayer.in/privacy-policy. If we make a change to this policy that, in our sole discretion, is material, we will notify you via an email to the email address associated with your account or through the Services.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
