import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import "./ContactUs.css";
import email from "../assets/email.svg";
export default function ContactUs() {
  return (
    <div>
      <NavBar />
      <div className="tile-container">
        <div className="blur-tile contactus">
          <div className="contact-ui">
            <div className="large-title help">Contact Us</div>
            <div className="email-box">
              <img src={email} alt="" />
              <span>Email: needhelp.savetoshare@gmail.com</span>
            </div>
            <div className="sub-text">
              Due to world biggest free video sharing platform, We recived daily
              high numbers of emails around the world, We usally reply under
              24-48 hours. Thanks for being here.
            </div>

            <div className="sub-text">Contact Number: 9835036622</div>
            <div className="sub-text">
              COMPANY ADDRESS : SPDJ MEDIA PRIVATE LIMITED, C/O JITENDRA KUMAR
              DANGI, BASARIYA, SEC.1, HAZARIBAG, JHARKHAND, india, 825406
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
