import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import "./Help.css";
import Collapsible from "react-collapsible";
import chevrondown from "../assets/chevron-down.svg";

export default function Help() {

  return (
    <div>
      <NavBar />
      <div className="tile-container">
        <div className="blur-tile help">
          <div className="flex-child first help">
            <div className="large-title help">Help Center</div>
            <div className="para-1 help">
              Help center provide you full clearnece about your all doubt about
              sharedisk platform Read our FAQ
            </div>
          </div>
          <div className="flex-child accordion">
            <Collapsible
              trigger={
                <div className="custom-collapsible">
                  <span>What is ShareDisk?</span>
                  <img src={chevrondown} className="collapsible-icon" alt="" />
                </div>
              }
            >
              <div className="collapsible-body">
              ShareDisk is a cloud-based video Hosting platform in which anyone can upload video files to generate links and Earn Money.
              </div>
            </Collapsible>

            <Collapsible
              trigger={
                <div className="custom-collapsible">
                  <span>What is Creator Program in ShareDisk</span>
                  <img src={chevrondown} className="collapsible-icon" alt="" />
                </div>
              }
            >
              <div className="collapsible-body">
              The Creator Program in ShareDisk is an exclusive opportunity for video creators and social media influencers to gain revenue.
              </div>
            </Collapsible>

            <Collapsible
              trigger={
                <div className="custom-collapsible">
                  <span>What is the minimum withdrawal Limit?</span>
                  <img src={chevrondown} className="collapsible-icon" alt="" />
                </div>
              }
            >
              <div className="collapsible-body">
              The minimum Payout of ShareDisk is Rs 25 ( for UPI ) and $5 ( for PayPal ). Once you earning reaches the threshold amount your payment will automatically send in your UPI/PayPal payment ID.
              </div>
            </Collapsible>
            <Collapsible
              trigger={
                <div className="custom-collapsible">
                  <span>Payment Method & Payment Mode</span>
                  <img src={chevrondown} className="collapsible-icon" alt="" />
                </div>
              }
            >
              <div className="collapsible-body">
              The available payment mode are UPI & Paypal.
              </div>
            </Collapsible>
            <Collapsible
              trigger={
                <div className="custom-collapsible">
                  <span>When i get paid from ShareDisk?</span>
                  <img src={chevrondown} className="collapsible-icon" alt="" />
                </div>
              }
            >
              <div className="collapsible-body">
                For UPI users we pay daily and for PayPal users payments are made on every 1st day of the month. 

              </div>
            </Collapsible>
            <Collapsible
              trigger={
                <div className="custom-collapsible">
                  <span>How often do you pay?</span>
                  <img src={chevrondown} className="collapsible-icon" alt="" />
                </div>
              }
            >
              <div className="collapsible-body">
              For UPI users we pay daily and for PayPal users payments are made on every 1st day of the month. 

              </div>
            </Collapsible>
          </div>
          <div className="flex-child accordion second">
            <Collapsible
              trigger={
                <div className="custom-collapsible">
                  <span>How to earn Money from ShareDisk?</span>
                  <img src={chevrondown} className="collapsible-icon" alt="" />
                </div>
              }
            >
              <div className="collapsible-body">
              Anyone Can Earn Money Using ShareDisk, Just Upload the video on Sharedisk and get links for that video, Now you can share those links anywhere when someone watches or downloads that video then the uploader gets paid from shareDisk.
              </div>
            </Collapsible>

            <Collapsible
              trigger={
                <div className="custom-collapsible">
                  <span>Video upload size limit in ShareDisk?</span>
                  <img src={chevrondown} className="collapsible-icon" alt="" />
                </div>
              }
            >
              <div className="collapsible-body">
              ShareDisk is providing Unlimited Cloud storage to the Publishers, So yes you can upload larger files in ShareDisk.
              </div>
            </Collapsible>

            <Collapsible
              trigger={
                <div className="custom-collapsible">
                  <span>CPM for every 1000 video views?</span>
                  <img src={chevrondown} className="collapsible-icon" alt="" />
                </div>
              }
            >
              <div className="collapsible-body">
              Our CPM is Rs 50 means for every 1000 views you will get paid Rs50.
              </div>
            </Collapsible>
            <Collapsible
              trigger={
                <div className="custom-collapsible">
                  <span>Where I can get customer support?</span>
                  <img src={chevrondown} className="collapsible-icon" alt="" />
                </div>
              }
            >
              <div className="collapsible-body">
              You can see Messanger chat support available in your Dashboard. From there you can contact customer support.
              </div>
            </Collapsible>
            <Collapsible
              trigger={
                <div className="custom-collapsible">
                  <span>Why my video is removed/takedown?</span>
                  <img src={chevrondown} className="collapsible-icon" alt="" />
                </div>
              }
            >
              <div className="collapsible-body">
              Because your video did not follow our terms and conditions or your video was claimed by the copyright holder.
              </div>
            </Collapsible>
            <Collapsible
              trigger={
                <div className="custom-collapsible">
                  <span>Why my ShareDisk acount got banned?</span>
                  <img src={chevrondown} className="collapsible-icon" alt="" />
                </div>
              }
            >
              <div className="collapsible-body">
                There may be several reasons your account can get banned. Mostly it is due to sending invalid traffic from VPN, bots and self views.
              </div>
            </Collapsible>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
