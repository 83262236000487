import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import "./ContactUs.css";
export default function RefundPolicy() {
  return (
    <div>
      <NavBar />
      <div className="tile-container">
        <div className="blur-tile privacy">
          <div className="contact-ui">
            <div className="large-title help">Refund Policy</div>
            <div className="sub-text">
            Generally, payments for shrdsk subscriptions are not refundable. If you have a problem with your account, you can contact need help.savetoshare@gmail.com for assistance (please do so from the same email address you’ve used to register your sharedisk.io account).

If you would like to cancel a subscription that was purchased on our website, this is not possible because its come into digital product subscription base model.

If you would like to cancel a subscription that was purchased using our website, it will automatically expire when your subscription expiry’s date end.

There are some limited situations where Shrdsk can provide you a refund on a shrdsk subscription you’ve purchased:

If you believe that shrdsk has charged you in error, you must contact us within 7 days of such charge to request a refund.
If you have made a payment by mistake and have not used the subscription quota you have purchased, you must contact needhelp.savetoshare@gmail.com within 1 hours to arrange a refund. all other purchases on shrdsk are final and non-refundable.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
