import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import HomePage from "./components/HomePage";
import CreatorProgram from "./components/CreatorProgram";
import OnlineVideoPlayer from "./components/OnlineVideoPlayer";
import Company from "./components/Company";
import DMCA from "./components/DMCA";
import Help from "./components/Help";
import ContactUs from "./components/ContactUs";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import RefundPolicy from "./components/RefundPolicy";
import PricingDetails from "./components/PricingDetails";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage/>}></Route>

        <Route path="/creator-program" element={<CreatorProgram/>}></Route>
        <Route path="/online-video-player" element={<OnlineVideoPlayer/>}></Route>
        <Route path="/company" element={<Company/>}></Route>
        <Route path="/dmca" element={<DMCA/>}></Route>
        <Route path="/faq" element={<Help/>}></Route>
        <Route path="/help" element={<Help/>}></Route>
        <Route path="/contact-us" element={<ContactUs/>}></Route>
        <Route path="/refund-policy" element={<RefundPolicy/>}></Route>
        <Route path="/pricing" element={<PricingDetails/>}></Route>
        {/* <Route path="/about-us" element={<AboutUs/>}></Route> */}
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}></Route>
        <Route path="/terms-and-conditions" element={<TermsAndConditions/>}></Route>
      </Routes>
    </BrowserRouter>
    {/* <HomePage/>  */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
