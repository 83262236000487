import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import "./DMCA.css";
import pirate from "../assets/pirate.svg";
import arrowRight from "../assets/arrow-right.png";
export default function DMCA() {
  return (
    <div>
      <NavBar />
      <div className="tile-container">
        <div className="blur-tile">
          <div className="large-title">Piracy/Copyright Free Experience </div>
          <div className="title-1 dmca">PIRACY</div>
          <div className="para-1">
            As the world's first free video sharing platform stopping piracy is our
            first priority. At ShareDisk we take piracy so serious, Thats why
            we have made sharedisk most advanced powerfull AI Based free video
            sharing platform. The loses we feel, The hard work we know, The pain
            we understand. That’s how we have made something powerful to stop
            piracy.
          </div>
          <span className="flex-title">
            <span>
              <span className="title-1 custom">HANDBRAKE TOOL</span>- Remove
              your copyrighted/pirated videos instantly from sharedisk
            </span>
          </span>

          <div className="para-1">
            To stop piracy in our platform we have made HandBreak Tool for all
            DMCA Removal companies, Using our HandBreak tool you can able to
            remove/takedown any video from our platform in just 1 click, And
            your copyrighted content will be removed under seconds. This is how
            we have made sharedisk more relaible for creators and DMCA
            companies.
          </div>

          <span className="flex-title">
            <span>
              <span className="title-1 custom">ELIGIBILITY</span>- eligiblity -
              for accses our handbreak tool
            </span>
          </span>

          <div className="para-1">
            As you know, We are giving very high authorities to delete any
            videos from our platform, So we need some information then you can
            get access of our handbreak tool. You need to provide your company
            documents like, INC, MOA, PAN OF COMPANY to get access of our tool.
          </div>

          <div className="tools-flex">
            <div className="info-line-2 pirate">
              <img src={pirate} className="sharedisk-small-img" alt="" />
              Get HandBreak Tool
              <img className="arrow-right" src={arrowRight} alt="" />
              <div className="betatag">Only For DMCA Companies</div>
            </div>

            <div className="info-line-2 copyright">
              For instant Removal of any copyrighted/Pirated Content In Our
              Platform Mail Us At:{" "}
              <span className="email"> needhelp.savetoshare@gmail.com</span>
              <div className="betatag">
                Your content will be removed ASAP, Stoping Piracy is our high
                prorites at sharedisk, #ShareDiskDMCATeam{" "}
              </div>
            </div>
          </div>
          <span className="email-info-mobile">
            For instant Removal of any copyrighted/Pirated Content In Our
            Platform Mail Us At:
            <span className="email">needhelp.savetoshare@gmail.com</span>
          </span>
          <span className="email-info-mobile">
            Your content will be removed ASAP, Stoping Piracy is our high
            prorites at sharedisk, #ShareDiskDMCATeam
          </span>
        </div>
      </div>
      <Footer />
    </div>
  );
}
