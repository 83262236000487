import React, { useState } from "react";
import "./NavBar.css";
// import logo from "../assets/sharedisklogo.svg";
import logo from "../assets/sharedisklarge.png";
import sophia from "../assets/sophialarge-min.png";
import menu from "../assets/menu.svg";
import { Link } from "react-router-dom";

import sharediskSmall from "../assets/sharedisk-small-min.png";
import arrowRight from "../assets/arrow-right.png";

export default function NavBar() {
  const [showMenuBar, setMenuBar] = useState(false);

  function showMenu(show) {
    setMenuBar(!showMenuBar);
    console.log("show");
  }
  return (
    <div className="navbar-container">
      <div className="navbar">
        <div className="flex-container">
          <Link to="/">
            <img src={logo} className="nav-img" alt="" />
          </Link>

          <div style={{ display: "flex", columnGap: "20px" }}>
            <img className="sophia mobile" src={sophia} alt="" />
            <img onClick={showMenu} src={menu} className="menu-burger" alt="" />
          </div>
          <div className="nav-links desktop">
            <Link to="/">Home</Link>
            {/* <Link to="/">Online Video Player</Link> */}
            <Link to="/creator-program">Creator Program</Link>
            <Link to="/company">Company</Link>
            <Link to="/dmca">DMCA</Link>
            <Link to="/faq">FAQ</Link>
            <a href="https://sharedisk.io/signup?ref=uASWk">Signup</a>
            <a href="/signin">Login</a>
            <img src={sophia} className="sophia desktop" alt="" />
          </div>
        </div>

        <div
          className="nav-links-mobile"
          style={{ height: showMenuBar ? "380px" : "0px" }}
        >
          <div className="mobile-container">
            {/* <div className="mobile-menu-item">
              <Link to="/online-video-player">Online Video Player</Link>
            </div> */}

            <div className="mobile-menu-item">
              <Link to="/">Home</Link>
            </div>

            <div className="mobile-menu-item">
              <Link to="/creator-program">Creator Program</Link>
            </div>

            <div className="mobile-menu-item">
              <Link to="/company">Company</Link>
            </div>

            <div className="mobile-menu-item">
              <Link to="/dmca">DMCA</Link>
            </div>

            <div className="mobile-menu-item">
              <Link to="/faq">FAQ</Link>
            </div>

            <div className="mobile-menu-item">
              <a href="https://sharedisk.io/signup?ref=uASWk">Signup</a>
            </div>

            <div className="mobile-menu-item">
              <a href="/signin">Login</a>
            </div>

            {/* <div className="mobile-menu-item"> */}
            {/* <img src={sophia} alt="" /> */}
            {/* </div> */}

<Link to="/creator-program" className="join-link">
          <div className="info-line-2">
            <img src={sharediskSmall} className="sharedisk-small-img" alt="" />
            Join Creator Program
            <img className="arrow-right" src={arrowRight} alt="" />
            <div className="betatag">Beta</div>
          </div>
</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
