import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import "./Company.css";
import jitendra from "../assets/jitendra.png";
import anupam from "../assets/anupam.png";
export default function Company() {
  return (
    <div>
      <NavBar />
      <div className="tile-container">
        <div className="blur-tile">
          <div className="large-title">Making Video Sharing Easy & Fast</div>
          <div className="title-1">COMPANY</div>
          <div className="para-1">
            ShareDisk was founded on 27/NOV/2022 as the free video sharing
            platform for everyone. We made ShareDisk for worldwide creators. We
            are holding the powerful servers with advanced AI features in our
            platform. The vision of the company is big and clean. We are here to
            make video sharing more easy for everyone in the worldwide, not only
            video sharing also focused on great user experince for our all
            creators and viewers. The whole world is happy and using our
            platform. We will always try to improve our services & AI to next
            level.
          </div>
          <div className="title-1">BACKED BY TOP SAAS COMPANY - SPDJ</div>

          <div className="para-1">
            ShareDisk is backed by India’s top SAAS based company SPDJ MEDIA
            PRIVATE LIMITED, We at SPDJ always try to make world better using
            technology and AI. We make highly AI Based and Technology made
            startup and scale over the world. Already changing millions of lifes
            using our SAAS products. Created over 1.5L+ creators worldwide. We
            make in India for world.
          </div>

          <div className="ceo-boxes">
            <div className="ceo-box">
              <img className="ceo-img" src={jitendra} alt="" />
              <div className="name-info">
                <div className="ceo-name">Jitendra Verma</div>
                <div className="ceo">CEO/ShareDisk</div>
                <div className="twitter-link">
                  <a target="_blank" href="https://twitter.com/JitendraSpdj">Follow Us On Twitter</a>
                </div>
              </div>
            </div>
            <div className="ceo-box">
              <img className="ceo-img" src={anupam} alt="" />
              <div className="name-info">
                <div className="ceo-name">Anupam Shinde</div>
                <div className="ceo">CO-Founder/ShareDisk</div>
                <div className="twitter-link">
                  <a target="_blank" href="https://twitter.com/JitendraSpdj">Follow Us On Twitter</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
