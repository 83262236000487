import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import "./ContactUs.css";
export default function PricingDetails() {
  return (
    <div>
      <NavBar />
      <div className="tile-container">
        <div className="blur-tile privacy">
          <div className="contact-ui">
            <div className="large-title help">Pricing</div>
            <div className="sub-text pricing">
            Shrdsk comes with two type subscription model.
            <ul>
                <li>
 Remove ads on single video ( INR 2 )
                </li>
                <li>
 Remove ads on all videos ( INR 15 )

                </li>
            </ul>
You can find our exact/updated rates in any video of shreds while playing a video.
For any pricing question ask anything: 

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
