import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import "./CreatorProgram.css";

import facebook from "../assets/facebooklarge-min.png";
import twitter from "../assets/twitterlarge-min.png";
import youtube from "../assets/youtubelarge-min.png";
import telegram from "../assets/telegram.svg";

import sharediskSmall from "../assets/sharedisk-small.png";
import arrowRight from "../assets/arrow-right.png";
import { Link } from "react-router-dom";

export default function CreatorProgram() {
  return (
    <div>
      <NavBar />
      <div className="main-flex">
        <div className="column-flex creator">
          <div className="main-heading">in-stream ads</div>
          <div className="main-heading-2">
            Earn money by inserting short ads into your qualifying videos.
          </div>

          <img
            src="https://i.ibb.co/B34hZh2/creatorprogram.png"
            className="main-img creator mobile"
            alt=""
          />

          <div className="info-line-mobile">
            World first video sharing platform with daily payments and full of
            powerful features
          </div>
          <div className="info-line-1 creator">
            World first video sharing platform with daily payments and full of
            powerful features
          </div>

          <div className="socials mobile">
          <a target="_blank" href="https://www.facebook.com/sharedisk/">
          <img src={facebook} alt="" />
          </a>
          <a target="_blank" href="https://twitter.com/sharedisk">
          <img src={twitter} alt="" />
          </a>
          <a target="_blank" href="https://www.youtube.com/@sharedisk">
          <img src={youtube} alt="" />
          </a>
          <a target="_blank" href="https://t.me/sharediskofficial">
            <img src={telegram} alt="" />
          </a>
          </div>
          <a style={{textDecoration:"none"}} href="https://sharedisk.io/signup?ref=uASWk" className="join-link">
            <div className="info-line-2 creator">
              <img
                src={sharediskSmall}
                className="sharedisk-small-img"
                alt=""
              />
              Get Started
              <img className="arrow-right" src={arrowRight} alt="" />
              <div className="betatag">Beta(new)</div>
            </div>
          </a>
          <div className="read-faq">
            <Link to="/faq">Read Creators FAQ !</Link>
          </div>
        </div>
        <img
          src="https://i.ibb.co/B34hZh2/creatorprogram.png"
          className="main-img creator"
          alt=""
        />
      </div>
      <Footer />
    </div>
  );
}
