import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import "./ContactUs.css";
export default function TermsAndConditions() {
  return (
    <div>
      <NavBar />
      <div className="tile-container">
        <div className="blur-tile contactus">
          <div className="contact-ui">
            <div className="large-title help">Terms & Conditions</div>
            <div className="sub-text">
              Terms and condition Terms and Conditions This ShareDisk Service
              Agreement (the "Agreement") describes the terms and conditions on
              which ShareDisk ("we") offer services to you ("User") served by
              ShareDisk. By using our services, the User agrees to be bound by
              the following terms and conditions: We reserve the right to
              disable direct linking on user accounts that are using excessive
              bandwidth. Due to the mass number of fraud users generating
              downloads from the Blogger Blogspot blog, we are not allowed to
              pay for earnings from Blogspot websites. 18+ Content like
              Pxxgraphy, nudixx, xexual images, Movies, and any kind of
              offensive images or videos are prohibited. Copyrighted material is
              also strictly prohibited. We reserve the right to decide on
              appropriate content and can delete images or videos at any time
              without User notification. Users must agree to comply with all
              laws which apply to their location, including copyright and
              trademark laws. Images, videos, and files that violate copyrights
              or trademarks are not allowed. If someone has an infringement
              claim against you, you will be asked to remove the copyrighted
              file until the issue is resolved. If there is a dispute between
              participants on this site, ShareDisk is under no obligation to
              become involved. ShareDisk is not liable for your images, videos
              or files, or any lost business due to the unavailability or loss
              of the website. We make no claims of future reliability in
              serving, hosting, or storing your images, videos, or files. We can
              change the cost per mile (CPM) at any time without informing
              users. Terms Of Service can be changed at any time without
              informing users. ShareDisk is committed to cooperating with any
              and all legal authorities if an investigation should arise. Online
              circulation/possession of child pornography or rape/gang
              rape-related content is a punishable offense.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
